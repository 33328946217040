import { useEffect, useRef, useState } from "react";
import Aos from "aos";
import VideoBg1 from "./assets/videos/video-1.mp4";
import VideoBg2 from "./assets/videos/video-2.mp4";
import ImageLogo from "./assets/images/img-logo.png";
import ImageBtnBuy from "./assets/images/btn-buy.png";
import ImageBtnChart from "./assets/images/btn-chart.png";
import ImageTool1 from "./assets/images/tool-1.png";
import ImageTool2 from "./assets/images/tool-2.png";
import ImageTool3 from "./assets/images/tool-3.png";
import ImageTool4 from "./assets/images/tool-4.png";
import ImageSection1 from "./assets/images/img-section-1.png";
import ImageTitle1 from "./assets/images/title-1.png";
import IconTele from "./assets/images/icon-tele.png";
import IconX from "./assets/images/icon-x.png";
import IconVolume from "./assets/images/icon-volume.png";
import IconMute from "./assets/images/icon-mute.png";
import ImageTitle2 from "./assets/images/title-2.png";
import ImageCoin from "./assets/images/img-coin.png";
import ImageTitle3 from "./assets/images/title-3.png";
import ImageTitle4 from "./assets/images/title-4.png";
import IconBullet from "./assets/images/icon-bullet.png";
import AudioBg from "./assets/audios/Sound_bg.mp3";
import AudioBg1 from "./assets/audios/1-second-of-silence.mp3";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  const [isShow, setIsShow] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = (value) => {
    const element = document.getElementById(value);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const audio = new Audio(AudioBg);
    audio.loop = true;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
    return () => {
      audio.pause();
      audio.src = "";
    };
  }, [isPlaying]);

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <div className={`modal ${isShow ? "" : "hidden"}`}>
        <button
          onClick={(e) => {
            e.preventDefault();
            const audio = new Audio(AudioBg);
            audio.loop = true;
            audio.play();
            setIsShow(false);
          }}
        >
          Join now
        </button>
      </div>
      <header className="page-header">
        <div className="video-background">
          <video autoPlay muted loop playsInline>
            <source src={VideoBg1} type="video/mp4" />
          </video>
        </div>
        <div className="container wrapper">
          <nav className="nav-menu">
            <div className="img-logo">
              <img src={ImageLogo} alt="" />
            </div>
            <ul>
              <li onClick={() => handleClick("about")}>About</li>
              <li onClick={() => handleClick("token")}>Tokenomics</li>
              <li onClick={() => handleClick("roadmap")}>Roadmap</li>
              <li onClick={() => handleClick("contact")}>Contact</li>
            </ul>
            <div className="actions">
              <a
                href="https://t.me/GangstaCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTele} alt="" />
              </a>
              <a
                href="https://twitter.com/GangstaCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconX} alt="" />
              </a>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setIsPlaying(!isPlaying);
                }}
              >
                <img src={isPlaying ? IconMute : IconVolume} alt="" />
              </a>
            </div>
          </nav>
          <div data-aos="fade-up" className="main-content">
            <div className="top">
              <h3>welcome to gangsta cat</h3>
              <p className="value">
                CA: CbhycZna5PAyiebe3TvXF22oZtdG29mTJgCzXL5RMTgK
              </p>
              <div className="btn-groups">
                <a href="">
                  <img src={ImageBtnBuy} alt="" />
                </a>
                <a
                  href="https://dexscreener.com/solana/Cp2DHDXuKNJoTby82sWGdoQod4jpP3mFRr2T9GuTQuUJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ImageBtnChart} alt="" />
                </a>
              </div>
              <div className="list-tools">
                <img src={ImageTool1} alt="" />
                <img src={ImageTool2} alt="" />
                <img src={ImageTool3} alt="" />
                <img src={ImageTool4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="page-main">
        <section id="about" data-aos="fade-up" className="section-about">
          <div className="container wrapper">
            <div className="content">
              <div className="left">
                <img src={ImageSection1} alt="" />
              </div>
              <div className="right">
                <div className="img-title">
                  <img src={ImageTitle1} alt="" />
                </div>
                <p>
                  With the unique combination of the cuteness of cats and the
                  toughness of gangsters, Gangsta Cat will definitely set a new
                  trend in the world of memes and internet culture. Images of a
                  cat wearing a leather jacket, sporting a fedora, and sitting
                  on a vintage car, or even a painting of a cat holding a gun,
                  could all become extremely popular and widely shared on social
                  media.
                </p>
                <p>
                  With the creativity and imagination of the internet community,
                  Gangsta Cat could participate in various meme formats, from
                  causing mischief in humorous situations to ensuring the safety
                  of the neighborhood in dangerous situations. The possibilities
                  are endless, and Gangsta Cat is sure to bring joy and
                  inspiration to people around the world.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="token" data-aos="fade-up" className="section-tokenomics">
          <div className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle2} alt="" />
            </div>
            <ul className="list-item">
              <li>
                <img src={ImageCoin} alt="" />
                <div className="content">
                  <p className="title">Total supply</p>
                  <p className="value">100M</p>
                </div>
              </li>
              <li>
                <img src={ImageCoin} alt="" />

                <div className="content">
                  <p className="title">Buy/Sell Tax</p>
                  <p className="value">0%</p>
                </div>
              </li>
              <li>
                <img src={ImageCoin} alt="" />

                <div className="content">
                  <p className="title">Liquidity</p>
                  <p className="value">BURN</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section id="roadmap" data-aos="fade-up" className="section-roadmap">
          <div className="video-background">
            <video autoPlay muted loop playsInline>
              <source src={VideoBg2} type="video/mp4" />
            </video>
          </div>
          <div className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle3} alt="" />
            </div>
            <ul className="list-item">
              <li className="item">
                <p>STAGE 1</p>
                <ul>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Community Creation</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Contract Deployment</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Website Development</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Pre-launch Marketing</span>
                  </li>
                </ul>
              </li>
              <li className="item">
                <p>STAGE 2</p>
                <ul>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Token Launch</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>More Marketing</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Solana Trending</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>CEX Listing</span>
                  </li>
                </ul>
              </li>
              <li className="item">
                <p>STAGE 3</p>
                <ul>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>KOL Partnership</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>NFT Release</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Marketplace Launch</span>
                  </li>
                  <li>
                    <img src={IconBullet} alt="" />
                    <span>Airdrop Campaign</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>
        <section data-aos="fade-up" className="section-how-to-buy">
          <div className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle4} alt="" />
            </div>
            <ul className="list-step">
              <li>
                <span className="content-1">STEP 1</span>
                <span className="content-2">Connect Your Wallet</span>
                <span className="content-3">
                  Visit raydium or birdeye, and connect your wallet (e.g.,
                  phantom, MetaMask, Trust Wallet).
                </span>
              </li>
              <li>
                <span className="content-1">STEP 2</span>
                <span className="content-2">Select $GCAT Token</span>
                <span className="content-3">
                  Choose sol as your payment and search for <span>$Gcat </span>
                  in the "Swap to" field. If not listed, enter the
                  <span> $Gcat</span> contract address.
                </span>
              </li>
              <li>
                <span className="content-1">STEP 3</span>
                <span className="content-2">Swap</span>
                <span className="content-3">
                  Input how much SOL you want to swap, check the estimated
                  <span> $GCAT</span> you'll receive, adjust slippage if needed,
                  and confirm the swap in your wallet. Make sure your wallet is
                  funded with enough ETH for the purchase and transaction fees.
                </span>
              </li>
            </ul>
          </div>
        </section>
        <section id="contact" data-aos="fade-up" className="section-community">
          <div className="container wrapper">
            <div className="img-footer">
              <img src={ImageSection1} alt="" />
            </div>
            <h3>
              JOIN THE
              <span> COMMUNITY</span>
            </h3>
            <p>Join us and become a real Gangsta in Gangsta Cat community</p>
            <div className="socials">
              <a
                href="https://t.me/GangstaCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTele} alt="" />
              </a>
              <a
                href="https://twitter.com/GangstaCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconX} alt="" />
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <p>
            Copyright © 2024 - All Rights Reserved By
            <span> Gangsta cat</span>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
